<!-- PaymentPage.vue -->
<template>
    <div id="adyen"></div>
</template>
  
  <script>
import AdyenCheckout from '@adyen/adyen-web'
import api from '@/api'

export default {
    name: 'PaymentPage',
    props: {
        resortID: {
            type: String,
        },
        transactionRef: {
            type: 'String',
        },
        amount: {},
        profileID: {
            type: String,
            default: '65666',
        },
        email: {
            type: String,
        },
        studio: {
            required: true,
            type: String,
        },
        timestopID: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            submitResponse: null,
            successRedirect: '',
            checkout: null,
            adyenDropin: null,
            transactionData: null,
            studioMerchantMapper: {
                '6489790d72347134883f6391': 'WellComeFitArbon',
                '648975b972347134883f5976': 'WellComeFitBasel',
                '62fb40daf811bd7ac52b7cbc': 'WellComeFitRikon',
                '5f3c1241d91ddb464c2648b0': 'WellComeFitGlarus',
                '5f3c30afd91ddb464c2648b1': 'WellComeFitBuelach',
                '6489776b72347134883f5e17': 'WellComeFitHerisau',
                '5f3d48195dd7955ad48de5e3': 'WellComeFitPfungen',
                '648ff12f5405300798992bdc': 'WellComeFitSirnach',
                '5f3c30e1d91ddb464c2648b4': 'WellComeFitEinstein',
                '662b6c2386ff6eb5a0e8cd2c': 'WellComeFitObfelden',
                '6489788772347134883f617f': 'WellComeFitOerlikon',
                '648979f672347134883f658e': 'WellComeFitWetzikon',
                '5f3c30f2d91ddb464c2648b5': 'WellComeFitDietlikon',
                '63ff18391a3ea60a03d2d2c6': 'WellComeFitSchwanden',
                '63e3c3fe7d6de41505928202': 'WellComeFitSteckborn',
                '5f326a24ae8863640c859e75': 'WellComeFitFrauenfeld',
                '5f3d482d5dd7955ad48de5e4': 'WellComeFitWinterthur',
                '6489780c72347134883f5f8a': 'WellComeFitNiederurnen',
                '5f3c30d0d91ddb464c2648b3': 'WellComeFitStGallenOst',
                '6489797472347134883f6451': 'WellComeFitWallisellen',
                '5f3c30bfd91ddb464c2648b2': 'WellComeFitStGallenWest',
            },
            additionalDropinConfig: {
                // openFirstPaymentMethod: true, // When enabled, Drop-in opens the first payment method automatically on page load
                // openFirstStoredPaymentMethod: true, // When enabled, Drop-in opens the payment method with stored card details on page load. This option takes precedence over openFirstPaymentMethod
                showStoredPaymentMethods: true, // Shows or hides payment methods with stored card details
            },
        }
    },
    methods: {
        async initTACPay(config, dropinConfig = {}) {
            const { sessionId, redirectResult } = this.getQueryParameters()

            const isRedirectBack = sessionId || redirectResult
            if (isRedirectBack) {
                await this.showGuestIsAlreadyInProcess()
            }

            if (!window.AdyenCheckout) {
                console.log('da isch de redirect link')
                setTimeout(() => this.initTACPay(config, dropinConfig), 200)
                return
            }

            const $tacpay = this.$refs['tacpay']

            // this.setNeedsToken(this.needsToken(dropinConfig, false))
            /*
            dropinConfig.showRemovePaymentMethodButton = true
            dropinConfig.onDisableStoredPaymentMethod = async (recurringDetailReference, resolve, reject) => {
                const res = await fetch(`${config.frontendPath}payment/disable/${recurringDetailReference}/`, {
                    method: 'POST',
                }).then((res) => res.text())

                if (res === 'ok') {
                    resolve()
                } else {
                    reject()
                }
            }
*/
            /*     dropinConfig.onSelect = (component) => {
                setTimeout(() => {
                    this.paymentMethodSelected(component)

                    let checks = 0
                    const tokenChecker = setInterval(async () => {
                        const componentRef = component.componentRef || {}
                        const props = componentRef.props || {}
                        const storedPaymentMethodId = props.storedPaymentMethodId

                        const $tokenDeleteBtn = document.querySelector(
                            '.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__header .adyen-checkout__button'
                        )
                        if ($tokenDeleteBtn) {
                            $tokenDeleteBtn.style.display = !storedPaymentMethodId ? 'block' : 'none'
                        }

                        this.shouldGenerateTokenChanged()

                        if (storedPaymentMethodId || checks++ > 10) {
                            clearInterval(tokenChecker)

                            if (storedPaymentMethodId) {
                                const res = await fetch(
                                    `${config.frontendPath}payment/disable/${storedPaymentMethodId}/check/`,
                                    {
                                        method: 'POST',
                                    }
                                ).then((res) => res.text())

                                if ($tokenDeleteBtn) {
                                    $tokenDeleteBtn.style.display = res === 'ok' ? 'block' : 'none'
                                }
                            }
                        }
                    }, 50)
                }, 10)
            }
*/
            const submitCall = async (isDetail, state) => {
                try {
                    if (!this.adyenDropin) {
                        const dropinCreationPromise = new Promise((resolve) => {
                            const creationConfig = { ...dropinConfig, onReady: resolve }
                            this.adyenDropin = this.checkout.create('dropin', creationConfig).mount('#adyen')
                        })
                        await dropinCreationPromise
                    }

                    this.adyenDropin.setStatus('loading')

                    this.submitResponse = await api.fetchPaymentSubmit(
                        state.data,
                        isDetail,
                        this.transactionData,
                        this.studioMerchantMapper[this.studio],
                        this.resortID,
                        this.email,
                        this.profileID
                    )

                    this.adyenDropin.handleResponse(this.submitResponse)
                } catch (error) {
                    this.adyenDropin.handleError(error)
                }
            }

            dropinConfig.onAdditionalDetails = (state) => submitCall(true, state)

            if (!isRedirectBack) {
                dropinConfig.onSubmit = (state) => {
                    if (dropinConfig.beforeSubmit) {
                        dropinConfig.beforeSubmit(state.data)
                    }
                    return submitCall(false, state)
                }
            }

            dropinConfig.beforeSubmit = (data, component, actions) => {
                if (data) {
                    const type = data.paymentMethod && data.paymentMethod.type

                    if (this.needsToken()) {
                        data.storePaymentMethod = true
                    } else if (type !== 'scheme') {
                        data.storePaymentMethod = false
                    }
                }

                if (actions) {
                    actions.resolve(data)
                }
            }

            dropinConfig.onReady = () => {
                this.shouldGenerateTokenChanged()
                this.preselectPaymethod()
            }

            config.paymentMethodsConfiguration = {
                card: {
                    enableStoreDetails: config.tokenOption,
                },
            }

            config.onPaymentCompleted = async (result) => {
                const { resultCode } = result

                this.progressFinished()

                if (['Authorised', 'Received', 'Pending'].includes(resultCode)) {
                    setTimeout(() => {
                        if (this.successRedirect) {
                            this.$router.push(this.successRedirect)
                        } else {
                            this.$emit('completed', this.submitResponse)
                        }
                    }, 1000)
                } else {
                    setTimeout(() => {
                        this.adyenDropin.setStatus('ready')
                    }, 5000)
                }
            }

            config.onError = async (error) => {
                this.handleDropInError(error)
            }

            if (isRedirectBack) {
                const currentSessionId = sessionId || config.sessionId

                await this.showGuestIsAlreadyInProcess()

                this.checkout = await AdyenCheckout({ ...config, sessionId: currentSessionId })
                this.ensureTACPayIsSelected($tacpay)

                setTimeout(() => {
                    this.checkout.submitDetails({ details: { redirectResult } })
                }, 10)
            } else {
                console.log('da isch config vorem checkout', config)
                this.checkout = await AdyenCheckout(config)
                this.adyenDropin = this.checkout.create('dropin', dropinConfig).mount('#adyen')
            }
        },

        getQueryParameters() {
            const params = new URLSearchParams(window.location.search)
            return {
                sessionId: params.get('sessionId'),
                redirectResult: params.get('redirectResult'),
            }
        },

        showGuestIsAlreadyInProcess() {
            // Implementation for showing payment in progress
        },

        setNeedsToken(needsToken) {
            // Implementation for setting needsToken
        },

        shouldGenerateTokenChanged() {
            const needsToken = this.needsToken()
            const storeDetailsInput = document.querySelector('.adyen-checkout__store-details input')
            if (storeDetailsInput) {
                if (needsToken) {
                    storeDetailsInput.checked = true
                    storeDetailsInput.addEventListener(
                        'click',
                        (e) => {
                            e.stopImmediatePropagation()
                            e.preventDefault()
                        },
                        true
                    )
                } else {
                    storeDetailsInput.removeEventListener(
                        'click',
                        (e) => {
                            e.stopImmediatePropagation()
                            e.preventDefault()
                        },
                        true
                    )
                }
            }
        },

        needsToken(dropinConfig, withFurther = true) {
            const needsDropinToken = dropinConfig && (dropinConfig.isOnlyToken || dropinConfig.needsToken)
            return needsDropinToken || false
        },

        handleDropInError(error) {
            console.log(error)
            this.$toast.error('Fehler beim Zahlen')
            return
            // Implementation for handling Drop-In error
        },

        paymentMethodSelected(component) {
            // Implementation for payment method selection
        },

        preselectPaymethod() {
            // Implementation for preselecting a payment method
        },

        ensureTACPayIsSelected($tacpay) {
            // Implementation for ensuring TAC|Pay is selected
        },

        progressFinished() {
            // Implementation for finishing the progress
        },
    },
    async mounted() {
        if (window.flutter_inappwebview) window.flutter_inappwebview.callHandler('allowAllRedirects')

        let configuration = await api.fetchTayPayPaymentConfig({
            amount: Math.abs(this.amount),
            transactionRef: this.transactionRef,
            email: this.email,
            merchant: this.studioMerchantMapper[this.studio],
            timestopID: this.timestopID,
        })
        this.transactionData = configuration.transactionData
        this.successRedirect = configuration.redirectUrlAfterSuccessfulPayment
        this.initTACPay(configuration.tacPayCheckoutConfig, this.additionalDropinConfig)
    },
}
</script>
  
  <style scoped>
.adyen-checkout__payment-methods-list {
    padding: 0 !important;
}

::v-deep .adyen-checkout__button__text {
    color: white;
}
::v-deep .adyen-checkout__card__brands__brand-wrapper {
    line-height: 1;
}
</style>